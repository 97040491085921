import { Fragment, useEffect } from 'react';
import Link from 'next/link';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, PlusIcon } from '@heroicons/react/solid';
import { useConnectedCompanies } from '../utils/hooks/useConnectedCompanies';
import { useUser } from '../utils/hooks/useUser';
import { useRouter } from 'next/router';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CompanySelector() {
  const router = useRouter();
  const { user, selectedCompany, changeCompany, signOut } = useUser();
  const { data, error } = useConnectedCompanies(user);

  // If the JWT has expired, sign out user and route to signin page
  useEffect(() => {
    if (error && error.message === 'JWT expired') {
      signOut();
      router.replace('/signin');
    }
  }, [error]);

  // Inget företag är valt, men det finns minst ett kopplat företag
  // Sätt det som aktivt företag. Används vid uppläggning av nya företag
  useEffect(() => {
    if (!selectedCompany && data?.length > 0) {
      onCompanyChange(data[0]);
    }
  }, [data]);

  const onCompanyChange = (company) => {
    // Set state in Context
    changeCompany(company);
  };

  return (
    <Listbox value={selectedCompany} onChange={onCompanyChange}>
      {({ open }) => (
        <>
          <Listbox.Label className='block text-sm font-medium text-gray-100'>
            Aktivt företag
          </Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-smutt-blue-500 focus:border-smutt-blue-500 sm:text-sm'>
              {selectedCompany ? (
                <>
                  <span className='block truncate'>
                    {selectedCompany?.name}
                  </span>
                  <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                    <SelectorIcon
                      className='w-5 h-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </span>
                </>
              ) : (
                <span className='block truncate'>Inget företag valt</span>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition duration-100 ease-in'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {data && data.length >= 1 ? (
                  <>
                    {data?.map((company) => (
                      <Listbox.Option
                        key={company.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-smutt-blue-600'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        value={company}
                      >
                        {({ selectedCompany, active }) => (
                          <>
                            <span
                              className={classNames(
                                selectedCompany
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {company?.name}
                            </span>

                            {selectedCompany ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-smutt-blue-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='w-5 h-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </>
                ) : (
                  <Link passHref href='/company/new'>
                    <div className='relative py-2 pl-3 cursor-default select-none pr-9'>
                      <span className='block truncate'>Lägg till företag</span>
                      <span className='absolute inset-y-0 right-0 flex items-center pr-4'>
                        <PlusIcon className='w-5 h-5' aria-hidden='true' />
                      </span>
                    </div>
                  </Link>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
