import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ChartBarIcon,
  HomeIcon,
  MenuIcon,
  UsersIcon,
  XIcon,
  CogIcon,
  LogoutIcon,
  CashIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useUser } from '../utils/hooks/useUser';
import Image from 'next/image';
import CompanySelector from './CompanySelector';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const router = useRouter();
  const { user, signOut: signOutUser } = useUser();

  const navigation = [
    { name: 'Hem', href: '/', icon: HomeIcon, current: router.route === '/' },
    {
      name: 'Lönebesked',
      href: '/salary',
      icon: ChartBarIcon,
      current: router.route === '/salary',
    },
    {
      name: 'Fakturor',
      href: '/invoices',
      icon: CashIcon,
      current: router.route === '/invoices',
    },
    {
      name: 'Inställningar',
      href: '/account',
      icon: CogIcon,
      current: router.route === '/account',
    },
    {
      name: 'Hjälp',
      href: 'https://smutt.se/support',
      icon: QuestionMarkCircleIcon,
      external: true,
    },
  ];

  const signOut = () => {
    signOutUser();
    router.replace('/signin');
  };

  return (
    <div className='flex h-screen overflow-hidden antialiased bg-gray-100'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-40 flex md:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity duration-300 ease-linear'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-300 ease-linear'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition duration-300 ease-in-out transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition duration-300 ease-in-out transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex flex-col flex-1 w-full max-w-xs bg-gray-800'>
              <Transition.Child
                as={Fragment}
                enter='duration-300 ease-in-out'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='duration-300 ease-in-out'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 pt-2 -mr-12'>
                  <button
                    type='button'
                    className='flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XIcon className='w-6 h-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='relative flex-1 h-0 pt-5 pb-4 overflow-y-auto'>
                <div className='flex items-center flex-shrink-0 px-4'>
                  <Image
                    layout='fixed'
                    width='150'
                    height='48'
                    src='https://res.cloudinary.com/bergius-development/image/upload/v1633975674/smutt_logga_vit_fvxeae.svg'
                    alt='Smutt logotyp'
                  />
                  <span className='absolute top-9 right-24 text-xs inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-yellow-100 text-yellow-800'>
                    Beta
                  </span>
                </div>
                <div className='px-2 py-2 mt-4'>
                  <CompanySelector />
                </div>
                <nav className='flex flex-col justify-between px-2 mt-5 space-y-1'>
                  {navigation.map((item) => (
                    <Link passHref key={item.name} href={item.href}>
                      <a
                        target={item.external ? '_blank' : '_self'}
                        className={classNames(
                          item.current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        {item.name}
                      </a>
                    </Link>
                  ))}
                  <button
                    className='flex items-center w-full px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
                    type='button'
                    onClick={signOut}
                  >
                    <LogoutIcon className='flex-shrink-0 w-6 h-6 mr-4 text-gray-400 group-hover:text-gray-300' />
                    <span className='text-base'>Logga ut</span>
                  </button>
                </nav>
              </div>
              <div className='flex flex-shrink-0 p-4 bg-gray-700'>
                <div className='flex-shrink-0 block group'>
                  <div className='flex items-center'>
                    <div className='flex items-center'>
                      <UsersIcon className='inline-block w-6 h-6 text-white' />
                      <div className='ml-3'>
                        <p className='text-sm font-medium text-white'>
                          {user?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden md:flex md:flex-shrink-0'>
        <div className='flex flex-col w-64'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex flex-col flex-1 min-h-0 bg-gray-800 '>
            <div className='relative flex flex-col flex-1 pt-5 pb-4 overflow-y-auto'>
              <div className='flex items-center flex-shrink-0 px-4 '>
                <Image
                  layout='fixed'
                  width='150'
                  height='48'
                  src='https://res.cloudinary.com/bergius-development/image/upload/v1633975674/smutt_logga_vit_fvxeae.svg'
                  alt='Smutt logotype'
                />
              </div>
              <span className='absolute top-9 right-7 text-xs inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-yellow-100 text-yellow-800'>
                Beta
              </span>
              <div className='px-4 my-4'>
                <CompanySelector />
              </div>
              <nav className='relative flex-1 px-2 mt-5 space-y-1 bg-gray-800'>
                <div className='flex flex-col justify-between h-full'>
                  <div>
                    {navigation.map((item) => (
                      <Link passHref key={item.name} href={item.href}>
                        <a
                          target={item.external ? '_blank' : '_self'}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden='true'
                          />
                          {item.name}
                        </a>
                      </Link>
                    ))}
                  </div>
                  <button
                    className='flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'
                    type='button'
                    onClick={signOut}
                  >
                    <LogoutIcon className='flex-shrink-0 w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-300' />
                    <span className='text-sm'>Logga ut</span>
                  </button>
                </div>
              </nav>
            </div>
            <div className='flex flex-shrink-0 p-4 bg-gray-700 '>
              <div className='flex-shrink-0 block w-full group'>
                <div className='flex items-center'>
                  <UsersIcon className='inline-block w-6 h-6 text-white' />
                  <div className='ml-3'>
                    <p className='text-sm font-medium text-white'>
                      {user?.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col flex-1 w-0 overflow-hidden'>
        <div className='pt-1 pl-1 md:hidden sm:pl-3 sm:pt-3'>
          <button
            type='button'
            className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <MenuIcon className='w-6 h-6' aria-hidden='true' />
          </button>
        </div>
        <main className='relative z-0 flex-1 overflow-y-auto focus:outline-none'>
          <div className='py-6'>
            <div className='px-4 mx-auto max-w-7xl sm:px-6 md:px-8'>
              {children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
