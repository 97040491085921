import Head from 'next/head';
import Image from 'next/image';
import FadeIn from '../components/FadeIn';
import Layout from '../components/Layout';
import kivra from '../public/kivra_logo.png';
import smutt from '../public/smutt_logo_white_bg.png';
import bllogo from '../public/bl_logo.png';
import fnxlogo from '../public/fnx_logo.png';
import groupIllustration from '../public/group_illustration.png';
import moneyIllustration from '../public/money_illustration.png';
import coWorkIllustration from '../public/coworking_illustration.png';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../utils/hooks/useUser';
import toast from 'react-hot-toast';
import { createClient } from '@supabase/supabase-js';
import { getTokens, updateRefreshToken } from '../utils/fortnox';
import { getFnxCompanyCookie, setFnxTokenCookie } from '../utils/cookies';
import { getURL } from '../utils/helpers';

export default function Home() {
  const { user } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (!user) {
      toast.error('Din session har löpt ut. Logga in igen.');
      router.replace('/signin');
    }
  }, [user]);

  return (
    <>
      <Head>
        <title>
          Smutt AB - din integrationspartner mellan Kivra och ditt ekonomisystem
        </title>
        <meta
          name='description'
          content='Skicka underlag från ditt ekonomisystem via Kivra'
        />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <Layout>
        <FadeIn>
          <main className='flex flex-col items-center'>
            <h1 className='text-4xl font-bold text-center'>
              Kul att du hittat hit! 👋🏻
            </h1>
            <div className='max-w-5xl mt-4 prose text-center'>
              <p className='text-base'>
                Vårt mål med Smutt är att möjliggöra och förenkla för dig
                gällande digitaliseringen av din verksamhet. Vår första produkt
                är nu här och den hjälper dig att nå ut till dina kunder och
                medarbetare via den digitala brevlådan Kivra.
              </p>

              <p className='mt-4 text-lg font-semibold'>Så här går det till:</p>
            </div>
            <div className='grid grid-cols-1 gap-6 sm:grid-cols-3'>
              <div className='flex flex-col items-center max-w-xs col-span-1'>
                <Image
                  layout='intrinsic'
                  width='285'
                  height='285'
                  placeholder='blur'
                  src={coWorkIllustration}
                  alt='Co-working Illustration'
                />
                <span className='text-lg font-semibold'>1.</span>
                <p className='mt-2 text-base text-center'>
                  Skapa ditt underlag i ditt ekonomisystem, precis som vanligt.
                  Exempel på underlag kan vara löner och kundfakturor.
                </p>
                <div className='flex flex-col items-center mt-4'>
                  <Image
                    layout='fixed'
                    placeholder='blur'
                    width={192}
                    height={36}
                    src={bllogo}
                    alt='Björn Lunden logotyp'
                  />
                  <Image
                    layout='fixed'
                    placeholder='blur'
                    width={150}
                    height={55}
                    src={fnxlogo}
                    alt='Fortnox logotyp'
                  />
                </div>
              </div>
              <div className='flex flex-col items-center max-w-xs col-span-1'>
                <Image
                  layout='intrinsic'
                  width='285'
                  height='285'
                  placeholder='blur'
                  src={groupIllustration}
                  alt='Group Illustration'
                />
                <span className='text-lg font-semibold'>2.</span>
                <p className='mt-2 text-base text-center'>
                  Underlaget läses in till oss från ditt ekonomisystem och du
                  navigerar enkelt och smidigt i vår dashboard. Här väljer du
                  vilket underlag du vill skicka till din mottagare.
                </p>
                <div className='mt-4'>
                  <Image
                    layout='fixed'
                    placeholder='blur'
                    width={100}
                    height={25}
                    src={smutt}
                    alt='Smutt logotyp'
                  />
                </div>
              </div>
              <div className='flex flex-col items-center max-w-xs col-span-1'>
                <Image
                  layout='intrinsic'
                  width='285'
                  height='285'
                  placeholder='blur'
                  src={moneyIllustration}
                  alt='Money Illustration'
                />
                <span className='text-lg font-semibold'>3.</span>
                <p className='mt-2 text-base text-center'>
                  Din mottagare får direkt en notis i sin app eller mail om att
                  underlaget levererats till deras digitala brevlåda hos Kivra.
                </p>
                <div className='mt-4'>
                  <Image
                    layout='fixed'
                    placeholder='blur'
                    width={100}
                    height={35}
                    src={kivra}
                    alt='Kivra logotyp'
                  />
                </div>
              </div>
            </div>
          </main>
        </FadeIn>
      </Layout>
    </>
  );
}

export async function getServerSideProps({ req, res, query }) {
  // If we dont have any query params, do nothing
  if (!query.code) {
    return {
      props: {},
    };
  }

  const supabaseAdmin = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.SUPABASE_SERVICE_ROLE_KEY
  );

  const redirectUri = `${getURL()}`;
  const tokens = await getTokens(query.code, redirectUri).then((res) =>
    res.json()
  );

  const user = await supabaseAdmin.auth.api.getUserByCookie(req);

  // Get the company id from cookies
  const companyId = getFnxCompanyCookie(req, res);

  // Update the refresh token in the database
  await updateRefreshToken(companyId, user.user.id, tokens.refresh_token);

  // Set the refresh token as a cookie
  setFnxTokenCookie(req, res, tokens.access_token);

  return {
    props: {},
  };
}
